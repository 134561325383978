<template>
  <div class="fields-container">
    <hub-text-field
      @keyup="onInputFocus('name')"
      @blur="onInputBlur"
      :error-messages="getErrorMessages('name')"
      class="modal-form__field"
      name="name"
      v-model="formData.name"
      :label="$t('defaultManualPricing.fields.name')"
    />
    <hub-text-field
      @keyup="onInputFocus('price')"
      @blur="onInputBlur"
      :error-messages="getErrorMessages('price')"
      name="price"
      v-model="formData.price"
      :label="$t('defaultManualPricing.fields.price') + currencyLabelSuffix"
      persistent-hint
    />
    <div class="break" />
    <store-data-dropdown
      name="currency"
      v-model="formData.currency"
      data-getter="billing/getOriginRegions"
      item-value-key="currency"
      item-text-key="currency"
      :label="$t('billing.currency')"
      :error-messages="getErrorMessages('currency')"
    />
    <store-data-dropdown
      name="originRegionDropdown"
      v-model="formData.originRegion"
      data-getter="billing/getOriginRegions"
      item-value-key="code"
      item-text-key="displayName"
      :label="$t('billing.originRegion')"
      :error-messages="getErrorMessages('originRegion')"
    />
    <store-data-dropdown
      name="generalLedgerCodeDropdown"
      v-model="formData.generalLedgerCode"
      data-getter="generalLedgerCode/getAll"
      item-value-key="code"
      item-text-key="name"
      :label="$t('generalLedgerCode.navTitle')"
      :error-messages="getErrorMessages('generalLedgerCode')"
    />
  </div>
</template>

<script>
import { HubTextField } from '@hubootech/ui-library'
import StoreDataDropdown from '@/components/forms/StoreDataDropdown.vue'
import { title } from '@/utilities/filters'

export default {
  name: 'InputFields',
  components: { HubTextField, StoreDataDropdown },
  props: {
    formData: {
      type: Object,
    },
  },
  computed: {
    currencyLabelSuffix() {
      const selectedCurrency = this.formData?.currency
      return selectedCurrency ? ` (${selectedCurrency})` : ''
    },
    errorMessages() {
      return this.$store.getters['core/validationErrors']
    },
  },
  methods: {
    title,
    onInputFocus(inputName) {
      this.activeField = inputName
    },
    onInputBlur() {
      this.activeField = ''
    },
    getErrorMessages(fieldName) {
      if (!this.errorMessages) return []

      return this.errorMessages[fieldName] ?? []
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
