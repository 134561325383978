<template>
  <modal-form
    :value="value"
    id="edit-manual-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('defaultManualPricing.editModal.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="formData" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="$emit('close')" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import { mapGetters } from 'vuex'
import InputFields from './InputFields.vue'

export default {
  name: 'DefaultManualPricingEditModal',
  components: {
    InputFields,
  },
  props: {
    value: Boolean,
    selected: { type: Object, required: true },
  },

  data() {
    return {
      formData: {
        name: this.selected.name ?? '',
        price: this.selected.price ?? '',
        currency: this.selected.currency ?? '',
        originRegion: this.selected.originRegion ?? '',
        generalLedgerCode: this.getGlCodeFromName(this.selected.generalLedgerCode) ?? '',
      },
      activeField: '',
    }
  },

  computed: {
    ...mapGetters('billing', ['getSelectedPriceListVersionId']),
    ...mapGetters('core', ['apiEndpointIsLoading', 'hasErrors']),
    loading() {
      return this.apiEndpointIsLoading({
        method: 'GET',
        url: '/manual-default-price-list/' + this.selected?.id,
      })
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('defaultManualPricing/update', {
        id: this.selected?.id,
        data: {
          ...this.formData,
          priceListVersionId: this.getSelectedPriceListVersionId,
        },
      })

      if (res?.status !== 200) return

      this.$emit('close')
      this.$emit('updated-item')
    },
    getGlCodeFromName(generalLedgerCodeName) {
      return this.$store.getters['generalLedgerCode/getAll'].find(
        glCode => glCode.name === generalLedgerCodeName
      )?.code
    },
  },
}
</script>
